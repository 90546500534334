import React from 'react';

const Header = () => {
  return (
    <header className="header">
      <nav className="navbar">
        <div className="container">
            <h1 className='h1-header-mobile'>Tehran Soceity</h1>
        </div>
      </nav>
    </header>
  );
};

export default Header;