import React from 'react';
import './App.css';
import Header from './Header';
import { DownloadDemo } from './qr-code-demo.tsx';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <Header/>
      <DownloadDemo/>

      </header>
      
    </div>
  );
}

export default App;
